<template>
	<div>
		<pui-datatable :modelName="modelName" :modelColumnDefs="modelColumnDefs"></pui-datatable>
	</div>
</template>

<script>
export default {
	name: 'resourcetype-grid',
	components: {},
	data() {
		return {
			modelName: 'resourcetype',
			modelColumnDefs: {}
		};
	}
};
</script>
